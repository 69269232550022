<div class="card bg-white">
  <div class="card-content text-center">
    <span class="text-xl font-bold text-primary" i18n>Račun je zaklenjen.</span>
    <p class="mb-2 mt-5">
      <span i18n>Vaš račun za uporabo platforme AutoBrief je trenutno ZAKLENJEN zaradi neuporabe platforme ali neplačila zapadlih obveznosti.</span>
    </p>
    <p class="my-2" *ngIf="accountConfig.activeAccountProductPackage.paymentUrl">
      <span i18n>Za nadaljnjo uporabo platforme vas prosimo, da račun čim prej poravnate. Do njega lahko dostopate </span><a
            href="{{accountConfig.activeAccountProductPackage.paymentUrl}}" i18n>TUKAJ</a>
    </p>
    <p class="my-2">
      <span i18n>Če ste obveznosti že poravnali ali želite ponovno pridobiti dostop do vašega računa, nas prosimo kontaktirajte na:</span>
    </p>
    <p>
        <span><i class="fa fa-envelope text-gray mr-2"></i><a
                href="mailto:info@briefd.io">info@briefd.io</a></span>
    </p>
    <p>
      <span><i class="fa fa-phone text-gray mr-2"></i><a href="tel:386018280181">+386 (0)1 828 0181</a></span>
    </p>
  </div>
</div>
